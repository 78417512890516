import React, { useEffect } from "react"
import Notifications from "../../../components/account/pages/notifications"
import { useTranslationContext } from "../../../context/TranslationContext"

const canonicals = {
  ["en-US"]: "/account/notifications",
  ["de-DE"]: "/de/konto/notifications",
}

const NotificationsPage = () => {
  const { setTranslationContext } = useTranslationContext()

  useEffect(() => {
    setTranslationContext({
      locale: "de-DE",
      canonicals: canonicals,
    })
  }, [setTranslationContext])

  return <Notifications />
}

export default NotificationsPage
