import React, { useEffect, useState } from "react"
import { Flex, Box, Text } from "theme-ui"
import { useAccountContext } from "../../../context/AccountContext"
import Layout from "../../layouts"
import AccountLayout from "../../layouts/account-layout"
import { Trans, useTranslation } from "react-i18next"
import Toggle from "../../new-ui/toggle/toggle"
import Medusa from "../../../services/api"

const Notifications = () => {
  const { t } = useTranslation()
  const { id, email } = useAccountContext()

  const [emailSubscribed, setEmailSubscribed] = useState()

  useEffect(() => {
    async function isSubscribed() {
      await Medusa.newsletter
        .subscribed(id)
        .then(({ data }) => {
          setEmailSubscribed(data.subscribed)
        })
        .catch((err) => console.error(err))
    }
    isSubscribed()
  }, [id])

  const handleEmailClicked = () => {
    if (!emailSubscribed) {
      Medusa.newsletter
        .signup({ email: email, ids: {} })
        .then(() => setEmailSubscribed(true))
        .catch((err) => console.error(err))
    } else if (emailSubscribed) {
      Medusa.newsletter
        .unsubscribe(id, { email: email })
        .then(() => setEmailSubscribed(false))
        .catch((err) => console.error(err))
    }
  }

  return (
    <Layout hideFooter={true}>
      <AccountLayout>
        <Box sx={{ px: ["0", "10%"], flex: 1 }}>
          <Text
            as="h1"
            sx={{
              fontWeight: "normal",
              fontSize: "21px",
              px: ["4%", 0],
              pb: "16px",
            }}
          >
            {t("notifications")}
          </Text>
          <Flex sx={{ a: { color: "dark", textDecoration: "none" } }}>
            <Setting
              title={t("email_subscription")}
              description={
                <Trans i18nKey="email_subscription_description">
                  Yes, sign me up for Tekla Fabrics news & other digital
                  communications. I confirm that I am above 15 years old and
                  reviewed <a href="/policy">Privacy Policy</a>. You can
                  unsubscribe at any time by clicking the link at the bottom of
                  a newsletter.
                </Trans>
              }
              isOn={emailSubscribed}
              onClick={handleEmailClicked}
            />
          </Flex>
        </Box>
      </AccountLayout>
    </Layout>
  )
}

export default Notifications

const Setting = ({ title, description, isOn, onClick }) => {
  return (
    <Flex sx={{ py: "16px", borderBottom: "grey", flex: 1 }}>
      <Flex sx={{ flexDirection: "column", flex: 1 }}>
        <Text as="h2" sx={{ fontSize: "17px", fontWeight: "normal" }}>
          {title}
        </Text>
        <Text sx={{ width: "50%", fontSize: "13px", py: "16px" }}>
          {description}
        </Text>
      </Flex>
      <Flex>
        <Toggle isOn={isOn} onClick={onClick} />
      </Flex>
    </Flex>
  )
}
